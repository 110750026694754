<template>
  <v-container fluid>
    <BreadHeader :title="'Tax Rate (SST)'">
      <v-btn color="#063058" class="white--text" @click="toggleTaxRatePage()">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </BreadHeader>

    <AError :api="apiDelTaxRate" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <!--BOC:[contents]-->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="taxRateData"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOC:[globalData]
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";
//EOC
//BOC:[mapState]
import { mapState } from "vuex";
//EOC

// import moment from "moment";

export default {
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  emits: ["toggleTaxRatePage"],
  data: () => ({
    //BOC:[apiGetTaxRate]
    apiGetTaxRate: new Api(),
    //EOC
    //BOC:[apiDelTaxRate]
    apiDelTaxRate: new Api(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    //BOC:[hrdcData]
    hrdcData: new HrdcData(),
    //EOC
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {},
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    btnDisabled: false,
    headers: [
      {
        text: "Index",
        align: "start",
        sortable: false,
        value: "number",
      },
      { text: "Tax Rate (%)", value: "taxRate" },
      { text: "Start Date", value: "taxRateStartDate" },
      { text: "End Date", value: "taxRateEndDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    taxRateData: [],
    dialog: false,
    dialogDelete: false,
    deleteItemData: null,
  }),
  watch: {
    //
  },
  async created() {
    // this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo =
      this.$store.state.hrdc_einvoicing.data.applicationUuid;
    //BOC:[model]
    this.model.getByKey(this.params.modelKey);
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetTaxRate: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/price/get-tax-rate`,
      },
      apiDelTaxRate: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/price/del-tax-rate`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetTaxRate.fetch();
    this.apiGetTaxRate.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          this.taxRateData = data;
        }
      } catch (err) {
        console.log(err);
      }

      // this.loadingDialog = !this.loadingDialog;
    });
    //EOC
    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);
    // if (!this.isTaxpodAdminRoles) {
    //   this.alertError.push("You are not allowed to perform this action!");
    //   this.btnDisabled = true;
    // }
  },
  mounted() {},
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    //EOC
    toggleTaxRatePage() {
      this.$emit("toggleTaxRatePage", "TaxRateAdd");
    },
    deleteItem(item) {
      this.deleteItemData = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.apiDelTaxRate.setParams({
        id: this.deleteItemData.id,
      });
      this.apiDelTaxRate.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "success",
              timeout: 2000,
            });
            this.apiGetTaxRate.fetch();
            return true;
          }

          this.$store.dispatch("showMessage", {
            message: "Error, please contact admin to assist",
            messageType: "error",
            timeout: 2000,
          });
        } catch (err) {
          console.log(err);
        }
      });

      this.apiDelTaxRate.fetch();

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
